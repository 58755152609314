import React, { useState, useEffect } from 'react';
import styles from './MockTest.module.css';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '../utils/utils'; 
import axiosInstance from '../axiosInstance/logout';

export default function MockTest() {
  const { direct_account, direct_main, _, direct_history, direct_payment, direct_contract, direct_privacy, direct_about, direct_example } = useNavigation();
  const [isVisible, setIsVisible] = useState(false);
  const [money, setMoney] = useState();
  const [helpVisible, setHelpVisible] = useState(false);

  const help_close = () => {
    setHelpVisible(false);
  }

  const direct_help = () => {
    if (helpVisible) {
      setHelpVisible(false);
    }else {
      setHelpVisible(true);
    }
    
  }

  const think_again = () => {
    setIsVisible(false);
  }

 

  // const direct_payment = () => {
  //   navigate('/payment');
  // }

  const start_test = () => {
    let user_money = parseInt(sessionStorage.getItem('money') || '0'); 

    if (user_money < 20) {
      alert("您的积分不足,请充值");
    } else {
      user_money = user_money - 20;
      sessionStorage.setItem("money", user_money);
      setMoney(user_money);

      // 使用 axiosInstance 替换 fetch
      axiosInstance.put('https://koispeak.com/api/user_profiles/profile/', {
        money: user_money
      })
      .then(response => {
        // 如果请求成功
        const user_email = localStorage.getItem("user_email");
        const encodedEmail = encodeURIComponent(user_email);
        const url = `https://koispeak.com/static/pages/pc1.html?email=${encodedEmail}`;
        const new_window = window.open(url, '_blank');

        if (!new_window || new_window.closed) {
          // 如果新窗口未打开，则恢复积分并提示用户
          user_money = user_money + 20;
          sessionStorage.setItem("money", user_money);
          setMoney(user_money);
          
          if (window.confirm("请允许浏览器进行跳转")) {
            window.open(url, '_blank');
          }
        }
      })
      .catch(error => {
        // 如果发生错误
        console.error('Error updating money:', error);
        alert('Error: ' + error.message);
      });
    }
  };
  // const navigate = useNavigate(); // 创建navigate函数实例
  // const direct_main = () => {
  //   navigate('/mainpage');
  // }
  const direct_practice = () => {
    // alert("口语练习暂时为开放!");
    setIsVisible(true);
  }
  // const direct_history = () => {
  //   navigate('/testhistory');
  // }
  // const direct_account = () => {
  //   navigate('/selfaccount');
  // }
  const missing_alert = () => {
    alert("功能尚未开放");
  }
  const [last_test, set_last_test] = useState(0);
  const [history_count, set_history_count] = useState(0);
  const [target_score, set_target_score] = useState(0);
  const [highest_score, set_highest_score] = useState(0);

  useEffect(() => {
    set_last_test(sessionStorage.getItem('last_test'));
    set_history_count(sessionStorage.getItem('history_count'));
    set_target_score(sessionStorage.getItem('target_score'));
    set_highest_score(sessionStorage.getItem('highest_score'));
    setMoney(sessionStorage.getItem("money"));
  
  }, []);

    return (
        <div className={styles.main_container}>
      <div className={styles.macbook_air_home}>
        <div className={styles.frame_1}>
          <div className={styles.about_us} onClick={direct_about}>
            <span className={styles.about_us_2}>关于我们</span>
          </div>
          <button className={styles.about_us_3} onClick={direct_contract}>
            <span className={styles.about_us_terms}>条款</span>
            </button>
          <button className={styles.about_us_4} onClick={direct_privacy}>
            <span className={styles.about_us_privacy}>隐私</span>
          </button>
          <div className={styles.about_us_5} onClick={direct_help}><span className={styles.about_us_help}>帮助</span></div>
        </div>
        <div className={styles.logo_tentative}></div>
        <div className={styles.flex_row_fab}>
          <div className={styles.frame_6}>
            <div className={styles.group}>
              <div className={styles.flex_column_ad}>
                <div className={styles.frame_7}>
                  <button className={styles.statistic_banner}>
                    <div className={styles.frame_8}>
                      <div className={styles.frame_9}><span className={styles.number_3}>{last_test}</span></div>
                      <div className={styles.frame_a}>
                        <span className={styles.days_ago}>天前</span>
                      </div>
                    </div>
                    <div className={styles.frame_b}>
                      <span className={styles.last_mock_exam}>上次模考</span>
                    </div></button
                  ><button className={styles.statistic_banner_c}>
                    <div className={styles.frame_d}>
                      <div className={styles.frame_e}>
                        <span className={styles.number_3_f}>{history_count}</span>
                      </div>
                      <div className={styles.frame_10}><span className={styles.text_9}>套</span></div>
                    </div>
                    <div className={styles.div_frame}>
                      <span className={styles.text_a}>题库已刷</span>
                    </div></button
                  ><button className={styles.button_5}>
                    <div className={styles.div_frame_11}>
                      <div className={styles.div_frame_12}>
                        <span className={styles.text_b}>{target_score}</span>
                      </div>
                    </div>
                    <div className={styles.div_frame_13}>
                      <span className={styles.text_c}>目标分数</span>
                    </div></button
                  ><button className={styles.button_6}>
                    <div className={styles.div_frame_14}>
                      <div className={styles.div_frame_15}>
                        <span className={styles.text_d}>{highest_score}</span>
                      </div>
                    </div>
                    <div className={styles.div_frame_16}>
                      <span className={styles.text_e}>最高分数</span>
                    </div>
                  </button>
                </div>
                <div className={styles.div_group}>
                  <div className={styles.div_frame_17}>
                    <div className={styles.div_frame_18}>
                      <span className={styles.text_f}>仿真模考</span>
                    </div>
                    <div className={styles.wrapper_6}>
                      <div className={styles.div_frame_19}>
                        <span className={styles.text_10}
                          >· 1:1复刻雅思口语全流程，题目随机</span
                        >
                      </div>
                      <div className={styles.div_frame_1a}>
                        <div className={styles.wrapper_8}>
                          <span className={styles.text_11}>· 获得</span
                          ><span className={styles.text_12} onClick={direct_example}>AI口语评分报告</span
                          ><span className={styles.text_13}>及全部录音</span>
                        </div>
                      </div>
                      <div className={styles.div_frame_1b}>
                        <span className={styles.text_14}>· 总时长约15到20分钟</span>
                      </div>
                    </div>
                    <div className={styles.text_with_icon}>
                      <div className={styles.light_bulb}>
                        <div className={styles.group_1c}>
                          <div className={styles.line}></div>
                          <div className={styles.line_1d}></div>
                          <div className={styles.line_1e}></div>
                          <div className={styles.flex_row}>
                            <div className={styles.line_1f}></div>
                            <div className={styles.line_20}></div>
                          </div>
                        </div>
                        <div className={styles.group_21}></div>
                      </div>
                      <div className={styles.practice_experience}>
                        <span className={styles.suitable}>适合</span
                        ><span className={styles.last_minute_review}>考前突击</span
                        ><span className={styles.suitable_22}>、</span
                        ><span className={styles.last_minute_review_23}>分数摸底</span>
                      </div>
                    </div>
                    <button className={styles.frame_24} onClick={direct_practice}>
                      <div className={styles.small_button}>
                        <span className={styles.start_simulation_test}>开始仿真模考</span>
                      </div>
                      {/* <div className={styles.rectangle}></div> */}
                    </button>
                  </div>
                  <div className={styles.frame_25} onClick={missing_alert}>
                    <div className={styles.frame_26}>
                      <span className={styles.simulation_test}>套题练习</span>
                    </div>
                    <div className={styles.type_explanation}>
                      <div className={styles.frame_27}>
                        <span className={styles.simulation_test_details}
                          >· 包含正式考试的三个部分，可选题目</span
                        >
                      </div>
                      <div className={styles.frame_28}>
                        <div className={styles.simulation_test_details_29}>
                          <span className={styles.get_all_recordings}
                            >· 获得全部录音，</span
                          ><span className={styles.no_score_report}>不包含</span
                          ><span className={styles.no_score_report_2a}>评分报告</span>
                        </div>
                      </div>
                      <div className={styles.frame_2b}>
                        <span className={styles.recreate_ielts_speaking_process}
                          >· 总时长约15到20分钟</span
                        >
                      </div>
                    </div>
                    <div className={styles.text_with_icon_2c}>
                      <div className={styles.light_bulb_2d}>
                        <div className={styles.group_2e}>
                          <div className={styles.line_2f}></div>
                          <div className={styles.line_30}></div>
                          <div className={styles.line_31}></div>
                          <div className={styles.flex_row_bb}>
                            <div className={styles.line_32}></div>
                            <div className={styles.line_33}></div>
                          </div>
                        </div>
                        <div className={styles.group_34}></div>
                      </div>
                      <div className={styles.random_question_bank}>
                        <span className={styles.suitable_35}>适合</span
                        ><span className={styles.understand_exam_process}
                          >了解考试流程</span
                        ><span className={styles.suitable_36}>、</span
                        ><span className={styles.understand_exam_process_37}
                          >尝试考试体验</span
                        >
                      </div>
                    </div>
                    <button className={styles.small_button_38}>
                      <div className={styles.check_practice_record}>
                        <span className={styles.rectangle_39}>开始套题练习</span>
                      </div>
                      <div className={styles.want_to_practice_specific_parts}></div>
                    </button>
                  </div>
                </div>
                <div className={styles.go_to_speaking_practice_question_bank}>
                  <span className={styles.want_to_practice_specific_parts_3a}
                    >想分part或者按题目进行专项训练？</span
                  ><span className={styles.go_to_speaking_practice_question_bank_3b} onClick={missing_alert}
                    >去口语练习真题库</span
                  ><span className={styles.see_it}>看看！</span>
                </div>
              </div>
              <button className={styles.button_frame} onClick={direct_history}>
                <div className={styles.small_button_3c}>
                  <span className={styles.check_practice_record_3d}>查看模考记录</span>
                </div>
                {/* <div className={styles.rectangle_3e}></div> */}
              </button>
            </div>
            <div className={styles.frame_3f}><span className={styles.mock_exam}>模拟考试</span></div>
            <div className={styles.divider}><div className={styles.line_40}></div></div>
          </div>
          <div className={styles.sidebar}>
            <div className={styles.frame_41}>
              <div className={styles.sidebar_home} onClick={direct_main}>
                <div className={styles.home}><div className={styles.icon}></div></div>
                <span className={styles.my_home}>我的主页</span>
              </div>
              <button className={styles.sidebar_my_home}>
                <div className={styles.mock_exam_42}>
                  <div className={styles.trophy}><div className={styles.icon_43}></div></div>
                </div>
                <span className={styles.mock_exam_44}>模拟考试</span>
              </button>
              <div className={styles.sidebar_my_home_45} onClick={direct_example}>
                <div className={styles.oral_practice}></div>
                <span className={styles.my_home_46}>查看样例</span>
              </div>
              <div className={styles.sidebar_my_home_47} onClick={direct_history}>
                <div className={styles.mock_record}></div>
                <span className={styles.my_home_48}>模考历史</span>
              </div>
            </div>
            <div className={styles.divider_49}><div className={styles.line_4a}></div></div>
            <div className={styles.sidebar_my_home_4b} onClick={direct_account}>
              <div className={styles.my_account}></div>
              <span className={styles.my_home_4c}>我的账户</span>
            </div>
            {/* <div className={styles.sidebar_my_home_4d}><div className={styles.help}></div></div> */}
          </div>
        </div>
      </div>
      {helpVisible && (
        <div className={styles.help_main_container}>
          <div className={styles.help_close} onClick={help_close} />
          <span className={styles.help_add_wechat}>添加客服微信</span>
          <span className={styles.help_get_materials}>领取海量备考资料助你备考顺利</span>
          <div className={styles.help_image} />
        </div>
      )}
      {isVisible && (
      <div className={styles.jump_main_container}>
        <div className={styles.jump_frame}>
          <span className={styles.jump_ready_to_go}>READY TO GO？</span>
        </div>
        <div className={styles.jump_line} />
        <span className={styles.jump_text_2}>
          每次模拟考试AI评分会花费20积分。如在考试途中退出，积分将无法退回。
          <br />
          为了提供更为准确的评估，我们推荐在安静且网络稳定的环境中进行测试。
        </span>
        <div className={styles.jump_frame_button}>
          <div className={styles.jump_frame_div}>
            <div className={styles.jump_my_points}>
              <span className={styles.jump_my_points_1}>我的积分：</span>
              <span className={styles.jump_points}>{money}</span>
            </div>
          </div>
          <div className={styles.jump_frame_2}>
            <div className={styles.jump_frame_3} onClick={direct_payment}>
              <span className={styles.jump_purchase_score}>购买积分</span>
            </div>
          </div>
        </div>
        <div className={styles.jump_flex_row_a} onClick={think_again}>
          <button className={styles.jump_small_button}>
            <span className={styles.jump_i_am_thinking} >我再想想</span>
          </button>
          <button className={styles.jump_small_button_4} onClick={start_test}>
            <span className={styles.jump_start_mock_exam}>开始模考</span>
          </button>
          <div className={styles.jump_rectangle} />
          <div className={styles.jump_rectangle_5} />
        </div>
      </div>
      )}
    </div>
    );
}
