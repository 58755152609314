import React from 'react';
import styles from './Contract.module.css';
import { useNavigate  } from 'react-router-dom';

export default function UserContract() {
  const navigate = useNavigate();
  const jumpBack = () => {
    navigate(-1);
  }
  return (
    <div className={styles.main_container}>
      <span className={styles.user_agreement}>用户协议</span>
      <span className={styles.ai_ielts_speaking_assessment}>
        欢迎使用我们的AI雅思口语评估应用（以下简称"本应用"）。本应用旨在帮助雅思考生评估口语水平，提供模拟考试和详细的评分报告。请仔细阅读以下全部内容。使用本应用即表示您已充分阅读、理解并接受本协议的全部条款。
        <br />
        <br />
        1. 服务说明
        <br />
        1.1
        本应用的主要功能是为雅思考生提供口语水平评估服务，包括模拟考试和评分报告。
        <br />
        1.2
        您需要提供真实、准确、最新和完整的个人资料，包括电子邮箱或微信账号作为登录信息。
        <br />
        1.3 本应用主要面向成年人，特别是大学生群体和需要参加雅思考试的职场人士。
        <br />
        1.4
        您应当妥善保管账号及密码信息，对使用您的账号和密码进行的一切活动负全部责任。
        <br />
        <br />
        2. 用户行为规范
        <br />
        2.1 您承诺不会利用本应用从事任何违法或不当的行为，包括但不限于：
        <br />   -
        上传、发布、传播任何违法、有害、威胁、滥用、骚扰、侵害他人隐私或其他权利的内容
        <br />   - 冒充他人或虚构身份
        <br />   - 干扰或破坏本应用的正常运行
        <br />
        2.2
        如发现任何违反本协议的行为，本应用有权立即终止向您提供服务，并保留追究法律责任的权利。
        <br />
        <br />
        3. 知识产权
        <br />
        3.1
        本应用的所有内容，包括但不限于文字、图片、音频、视频、软件、程序、数据等，均受著作权法和其他相关法律法规的保护。
        <br />
        3.2 未经本应用书面许可，您不得以任何形式复制、修改、传播或使用上述内容。
        <br />
        <br />
        4. 免责声明
        <br />
        4.1
        本应用不对您使用本服务的结果做出任何保证，包括但不限于评分的准确性、可靠性等。
        <br />
        4.2
        对于因不可抗力、计算机病毒或黑客攻击、系统不稳定等原因造成的服务中断或其他缺陷，本应用不承担任何责任。
        <br />
        <br />
        5. 协议修改
        <br />
        5.1 本应用保留随时修改本协议的权利，修改后的协议将在本页面上公布。
        <br />
        5.2
        如果您不同意修改后的条款，请立即停止使用本应用。继续使用本应用将视为接受修改后的协议。
        <br />
        <br />
        6. 终止服务
        <br />
        6.1
        您同意本应用可以自行全权决定以任何理由终止您的账号或本服务的部分或全部，包括但不限于缺乏使用或本应用认为您已经违反本协议的条款。
        <br />
        6.2 服务终止后，本应用没有义务为您保留或向您披露您账号中的任何信息。
        <br />
        <br />
        7. 法律管辖
        <br />
        7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
        <br />
        7.2
        如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本应用所在地的人民法院提起诉讼。
      </span>
      <button className={styles.button} onClick={jumpBack}>
        <span className={styles.close}>关闭</span>
      </button>
    </div>
  );
}