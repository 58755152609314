import React, { useState, useEffect } from 'react';
import styles from './SelfAccount.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useNavigation } from '../utils/utils'; 
import eyeOpen from "../assets/login/images/open.png"; // 导入图片
import eyeClose from "../assets/login/images/close.png"; // 导入图片
import axiosInstance from '../axiosInstance/logout';

export default function TestHistory() {
  const { _, direct_main, direct_mock, direct_history, direct_payment, direct_contract, direct_privacy, direct_about, direct_example } = useNavigation();
  const [isVisible, setIsVisible] = useState(false);
  const [helpVisible, setHelpVisible] = useState(false);

  const help_close = () => {
    setHelpVisible(false);
  }

  const direct_help = () => {
    if (helpVisible) {
      setHelpVisible(false);
    }else {
      setHelpVisible(true);
    }
    
  }

  const think_again = () => {
    setIsVisible(false);
  }


  const [user_password, setPassword] = useState('**************');
  const [money, setMoney] = useState();
  const [user_email, setEmail] = useState('examplemail@koispeak.com');
  // const navigate = useNavigate(); // 创建navigate函数实例
  const [showPassword, setShowPassword] = useState(false);

  const direct_practice = () => {
    // alert("口语练习暂时为开放!");
    setIsVisible(true);

  }

  const changePassword = () => {
    // console.log("email", localStorage.getItem("user_email"))
    axiosInstance.post('https://koispeak.com/api/accounts/ecode_email/', {
      email: localStorage.getItem("user_email"),
    })
    .then(response => {
      const token = response.data.token; 
      const url = `https://koispeak.com/reset?token=${token}`;
      const new_window = window.open(url, '_blank');
      if (!new_window || new_window.closed) {
        if (window.confirm("请允许浏览器进行跳转")) {
          window.open(url, '_blank');
        }
      }
      
    })
    .catch(error => {
      alert(error)
      
    });
  }
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
          // console.log(accessToken)
          axiosInstance.get('https://koispeak.com/api/user_profiles/profile/money/', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`, // 确保从 localStorage 中获取 access token
            },
        })
        .then(response => {
          sessionStorage.setItem("money", response.data.money)
          setMoney(sessionStorage.getItem("money"));
          // console.log("user_email", localStorage.getItem("user_email"))
            // 处理响应
        })
        .catch(error => {
            console.error("There was an error fetching the user profile!", error);
        });
      } catch (err) {
          console.log(err);
      }
  };
  fetchUserProfile();
  // setMoney(sessionStorage.getItem("money"));
  setEmail(localStorage.getItem("user_email"));

  }, []); // 空依赖数组 [] 使得这个 effect 只在组件首次挂载时运行
  const handleShowPassword = () => {
    if (!showPassword) {
      setPassword(localStorage.getItem("password")); // 显示密码
      setShowPassword(true);
    } else {
      setPassword('**************'); // 显示密码
      setShowPassword(false);
    }
    
  };

  const handleCopyClick = () => {
    // 发送请求到 find-promo-code API 获取邀请码
    axiosInstance.post('https://koispeak.com/api/promo_code/find-promo-code/', {
      user_email: localStorage.getItem("user_email"),  // 传递用户的邮箱
    })
    .then(response => {
      // 处理返回的数据
      const data = response.data;
  
      if (data.promo_code) {
        // 如果请求成功，获取返回的邀请码
        const promoCode = data.promo_code;
  
        // 将邀请码复制到剪切板
        navigator.clipboard.writeText(promoCode).then(() => {
          alert(`邀请码${promoCode}已复制到剪切板！`);
        }).catch(err => {
          alert('复制失败');
        });
      } else {
        alert('未找到优惠码');
      }
    })
    .catch(error => {
      // 处理请求错误
      alert('获取优惠码失败');
    });
  };
  const [isHovered, setIsHovered] = useState(false);
 
    return (
      <div className={styles.main_container}>
      <div className={styles.macbook_air_home}>
        {isHovered && (
          <div className={styles.help_info}>
            <span className={styles.help_info_content}>
              如果朋友在付款时使用了你的邀请优惠码，你会获赠20积分，即免费享受一次AI模考评分！
            </span>
        </div>
        )}
        <div className={styles.frame_1}>
          <div className={styles.about_us} onClick={direct_about}><span className={styles.about_us_2}>关于我们</span></div>
          <div className={styles.about_us_3} onClick={direct_contract}><span className={styles.about_us_terms}>条款</span></div>
          <div className={styles.about_us_4} onClick={direct_privacy}>
            <span className={styles.about_us_privacy}>隐私</span>
          </div>
          <div className={styles.about_us_help} onClick={direct_help}><span className={styles.about_us_5}>帮助</span></div>
        </div>
        <div className={styles.logo_tentative}></div>
        <div className={styles.flex_row_d}>
          <div className={styles.regroup_i}>
            <div className={styles.frame_6}>
              <div className={styles.frame_7}>
                <div className={styles.image}>
                  <div className={styles.paper_duotone}>
                    {/* <div className={styles.vector}></div>
                    <div className={styles.rectangle}></div>
                    <div className={styles.edit_duotone}></div>
                    <div className={styles.group}></div>
                    <div className={styles.vector_8}></div>
                    <div className={styles.vector_9}></div>
                    <span className={styles.ai}>AI</span>
                    <div className={styles.vector_a}></div> */}
                  </div>
                </div>
                <div className={styles.frame_b}>
                  <div className={styles.frame_c}>
                    <span className={styles.latest_ai_ielts_speaking_technology}
                      >最新AI雅思口语测评技术</span
                    >
                  </div>
                </div>
                <div className={styles.frame_d}>
                  <div className={styles.frame_e}>
                    <div className={styles.text_with_icon}>
                      <div className={styles.done_ring_round}></div>
                      <span className={styles.random_topic_bank}>快速摸底</span>
                    </div>
                    <div className={styles.text_with_icon_f}>
                      <div className={styles.done_ring_round_10}></div>
                      <span className={styles.random_topic_bank_11}>精准测评</span>
                    </div>
                  </div>
                  <div className={styles.frame_12}>
                    <div className={styles.text_with_icon_13}>
                      <div className={styles.done_ring_round_14}></div>
                      <span className={styles.random_topic_bank_15}>智能分析</span>
                    </div>
                    <div className={styles.text_with_icon_16}>
                      <div className={styles.done_ring_round_17}></div>
                      <span className={styles.random_topic_bank_18}>定制提分</span>
                    </div>
                  </div>
                </div>
                <button className={styles.frame_19}>
                  <span className={styles.my_ai_score_voucher}>我的积分:</span
                  ><span className={styles.text_c}>{money}</span>
                  </button>
                <button className={styles.frame_1a} onClick={direct_example}>
                  <div className={styles.small_button}>
                    <span className={styles.view_sample_score_report}
                      >查看示例评分报告</span
                    >
                  </div>
                  {/* <div className={styles.rectangle_1b}></div> */}
                </button>
              </div>
              <div className={styles.group_1c}>
                <div className={styles.frame_1d}>
                  <div className={styles.frame_1e} onClick={direct_payment}>
                    <span className={styles.purchase_score}>购买评分券</span>
                  </div>
                </div>
                <div className={styles.group_1f}>
                  <div className={styles.frame_20} onClick={handleCopyClick}>
                    <div className={styles.frame_21}>
                      <span className={styles.copy_invite_code}>复制我的邀请码</span>
                    </div>
                  </div>
                  <div className={styles.info}  
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}></div>
                </div>
                <div className={styles.frame_22}>
                  <div className={styles.frame_23}>
                    <span className={styles.customer_service_appeal}>客服申诉</span>
                  </div>
                </div>
              </div>
              <div className={styles.frame_24}>
                <span className={styles.my_subscription}>我的订阅</span>
              </div>
              <div className={styles.divider}><div className={styles.line}></div></div>
            </div>
            <div className={styles.sidebar}>
              <div className={styles.frame_25}>
                <div className={styles.sidebar_my_homepage} onClick={direct_main}>
                  <div className={styles.home}><div className={styles.icon}></div></div>
                  <span className={styles.my_homepage}>我的主页</span>
                </div>
                <div className={styles.sidebar_my_homepage_26} onClick={direct_mock}>
                  <div className={styles.mock_exam}>
                    <div className={styles.trophy}><div className={styles.icon_27}></div></div>
                  </div>
                  <span className={styles.my_homepage_28}>模拟考试</span>
                </div>
                <div className={styles.sidebar_my_homepage_29} onClick={direct_example}>
                  <div className={styles.oral_practice}></div>
                  <span className={styles.my_homepage_oral_practice}>查看样例</span>
                </div>
                <div className={styles.sidebar_my_homepage_2a} onClick={direct_history}>
                  <div className={styles.mock_exam_record}></div>
                  <span className={styles.my_homepage_mock_exam_history}>模考历史</span>
                </div>
              </div>
              <div className={styles.divider_2b}><div className={styles.line_2c}></div></div>
              <button className={styles.sidebar_my_homepage_2d}>
                <div className={styles.my_account}></div>
                <span className={styles.my_homepage_my_account}>我的账户</span>
              </button>
              {/* <div className={styles.sidebar_my_homepage_2e}><div className={styles.help}></div></div> */}
            </div>
          </div>
          <div className={styles.frame_2f}>
            <div className={styles.my_account_30}>
              <div className={styles.frame_31}>
                <span className={styles.register_email}>注册邮箱</span>
              </div>
              <div className={styles.frame_32}>
                <div className={styles.frame_input} />
                <span className={styles.register_email_33}>{user_email}</span>
              </div>
            </div>
            <div className={styles.group_34}>
              <div className={styles.frame_35} onClick={changePassword}>
                <div className={styles.frame_36}>
                  <span className={styles.purchase_score_37}>修改密码</span>
                </div>
              </div>
              {/* <div className={styles.frame_38}>
                <div className={styles.frame_39}>
                  <span className={styles.purchase_score_3a}>注销账号</span>
                </div>
              </div> */}
            </div>
            <div className={styles.frame_3b}>
              <span className={styles.my_account_3c}>我的账号</span>
            </div>
            <div className={styles.divider_3d}><div className={styles.line_3e}></div></div>
            <div className={styles.my_account_3f}>
              <div className={styles.frame_40}>
                <span className={styles.register_email_41}>账户密码</span>
              </div>
              <div className={styles.frame_42}>
                <div className={styles.frame_input_43} /><span className={styles.register_email_44}>{user_password}</span>
              </div>
            </div>
            <div className={styles.component} 
            onClick={handleShowPassword}
            style={{
              backgroundImage: `url(${showPassword ? eyeClose : eyeOpen})`, // 根据状态动态更改背景图
            }}>
            </div>
          </div>
        </div>
      </div>
      {helpVisible && (
        <div className={styles.help_main_container}>
          <div className={styles.help_close} onClick={help_close} />
          <span className={styles.help_add_wechat}>添加客服微信</span>
          <span className={styles.help_get_materials}>领取海量备考资料助你备考顺利</span>
          <div className={styles.help_image} />
        </div>
      )}
      {/* {isVisible && (
      <div className={styles.jump_main_container}>
        <div className={styles.jump_frame}>
          <span className={styles.jump_ready_to_go}>READY TO GO？</span>
        </div>
        <div className={styles.jump_line} />
        <span className={styles.jump_text_2}>
          每次模拟考试AI评分会花费20积分。
          <br />
          如在考试途中退出，积分将无法退回。请确保网络连接通常，并已做好考试准备。
        </span>
        <button className={styles.jump_frame_button}>
          <div className={styles.jump_frame_div}>
            <div className={styles.jump_my_points}>
              <span className={styles.jump_my_points_1}>我的积分：</span>
              <span className={styles.jump_points}>{money}</span>
            </div>
          </div>
          <div className={styles.jump_frame_2}>
            <div className={styles.jump_frame_3} onClick={direct_payment}>
              <span className={styles.jump_purchase_score}>购买积分</span>
            </div>
          </div>
        </button>
        <div  className={styles.jump_flex_row_a} onClick={think_again}>
          <button className={styles.jump_small_button}>
            <span className={styles.jump_i_am_thinking} >我再想想</span>
          </button>
          <button className={styles.jump_small_button_4} onClick={start_test}>
            <span className={styles.jump_start_mock_exam}>开始模考</span>
          </button>
          <div className={styles.jump_rectangle} />
          <div className={styles.jump_rectangle_5} />
        </div>
      </div>
      )} */}
    </div>
    );
}
