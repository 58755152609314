import React, { useState } from 'react';
import styles from './Drop.module.css'; // 确保已经导入了你的样式文件
import axiosInstance from '../axiosInstance/logout';

function Dropdown({ onPromoCodeSubmit  }) {
    const [inputCode, setInputCode] = useState('');
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState(''); // 控制消息的颜色

    // 发送请求验证优惠码
    const checkPromoCode = async () => {
        if (!inputCode) {
            setMessage("请输入优惠码");
            setMessageColor('red');
            return;
        }
    
        try {
            const response = await axiosInstance.post('https://koispeak.com/api/promo_code/check/', {
                code: inputCode
            });
    
            const data = response.data;
            // console.log("data", data)
            if (data.code === 200) {
                setMessage(data.detail || "优惠码有效");
                setMessageColor('black');
                onPromoCodeSubmit(inputCode, data.discount); 
            } else {
                setMessage(data.detail || "优惠码无效");
                setMessageColor('red');
            }
        } catch (error) {
            setMessage("网络错误，请稍后再试");
            setMessageColor('red');
        }
    }

    return (
        <div className={styles.dropdown}>
            <div className={styles.description}> 
                <span className={styles.description_text}>👇有优惠码/分享码？填在这里！👇</span>
            </div>
            <div className={styles.inputContainer}>
                <input
                    type="text"
                    value={inputCode}
                    onChange={(e) => setInputCode(e.target.value)}
                    placeholder="请输入优惠码"
                    className={styles.input}
                />
                
            </div>
            <button onClick={checkPromoCode} className={styles.confirmButton}>
                    <span className={styles.ok}>OK</span>  
                </button>

            {message && (
                <div style={{ color: messageColor }} className={styles.message}>
                    {message}
                </div>
            )}
        </div>
    );
}

export default Dropdown;