import React, { useState, useEffect  } from 'react';
import styles from './Forget.module.css';
import { useNavigate } from 'react-router-dom'; // 引入useNavigate钩子
import axios from 'axios';
import axiosInstance from '../axiosInstance/logout';

export default function Main() {
  const navigate = useNavigate(); // 创建navigate函数实例
  const ConfimRegistClick = () => {
    navigate('/login'); // 路由跳转到注册页面
  }
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('请输入注册邮箱');
  const [submitted, setSubmitted] = useState(false);
  const submit = () => {
    axiosInstance.post('https://koispeak.com/api/accounts/send_reset_email/', {
      email: email,
    }).then(response => {
      if (response.status === 200) {
        setMessage('提交成功！密码重置链接将在3分钟内发送至你的注册邮箱。');
        setSubmitted(true); // 用于控制 UI 组件的显示和隐藏
      }
    }).catch(error => {
      // 根据需要处理错误
      alert('提交失败，请稍后再试。');
    });
  }
  return (
    <div className={styles.main_container}> 
      <div className={styles.traced_image} />
      <div className={styles.logo_box}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.rectangle}>
        <span className={styles.reset_password}>重置密码</span>
        <span className={styles.register_email}>{message}</span>
        {!submitted && (
          <>
            <div className={styles.rectangle_1}>
              <input className={styles.email_example}
              type="email" 
              placeholder='example@mail.com' required 
              value={email}
              onChange={e => setEmail(e.target.value)}/>
            </div>
            <button className={styles.submit_button} onClick={submit}>
              <span className={styles.submit}>提交</span>
            </button>
          </>
        )}
        {submitted && (
        <button className={styles.submit_button} onClick={() => navigate('/login')}>
          <span className={styles.submit}>返回</span>
        </button>
        )}
        
      </div>
      <div className={styles.login_section}>
        <span className={styles.existing_account}>已有账号？</span>
        <span className={styles.login_button} onClick={ConfimRegistClick}>登录</span>
      </div>
    </div>
  );
}