import React from 'react';
import styles from './About.module.css';
import { useNavigate  } from 'react-router-dom';

export default function AboutUs() {
  const navigate = useNavigate();
  const jumpBack = () => {
    navigate(-1);
  }
  return (
    <div className={styles.main_container}>
      <span className={styles.user_agreement}>关于我们</span>
      <span className={styles.ai_ielts_speaking_assessment}>
      KoiSpeak团队致力于用AI科技赋能语言学习，专注于打造AI雅思口语学习一站式工具，成为用户的“雅思锦鲤”，逢考必上岸。
      <br /><br />
      KoiSpeak立项的初衷是解决团队成员自己的语言学习难关——没错，我们也“苦雅思久矣”。过去一两年，大语言模型突飞猛进，我们看到了它在语言学习方面的巨大潜能，希望淋过雨的自己能为更多后来人打伞。作为曾经的游戏/AI科技行业的从业者、多邻国的忠实用户、曾经的雅思考生，我们尝试把AI提供的定制化、强交互特性加入到游戏化的产品设计思路中去，让学习的枯燥少一点、趣味多一点，且真正意义上实现“为每一位用户量身定制的学习计划”来适应你独特的学习节奏。当然，我们也有游戏化的设计，帮助用户把学习变成像刷短视频一样的习惯。
      <br /><br />
      同时，面对市场上一次要求投入非常多的课程包、参差不齐的教学质量，我们希望去赋能有自己学习节奏的用户，用大家都能接受的价格门槛提供高效的语言学习辅助。
      <br /><br />
      团队的愿景很简单：希望通过我们的努力，能持续降低学习门槛，让你的雅思备考和英语学习更加轻松省力。诚然学海无涯，但雅思锦鲤会陪你一直游到上岸。
      <br /><br />
      再一次感谢你的信任，有任何对产品的建议或者反馈，欢迎大家以邮件形式发送至 pandafanwu@foxmail.com。
      <br /><br />
      KoiSpeak团队。
      </span>
      <button className={styles.button} onClick={jumpBack}>
        <span className={styles.close}>关闭</span>
      </button>
    </div>
  );
}