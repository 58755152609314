import { useNavigate } from 'react-router-dom';

export const useNavigation = () => {
    const navigate = useNavigate();
    
  
    const direct_account = () => {
      navigate('/selfaccount');
    };

    const direct_main = () => {
        navigate('/mainpage');
    }

    const direct_mock = () => {
        navigate('/mocktest');
    }

    const direct_history = () => {
        navigate('/testhistory');
    }

    const direct_payment = () => {
        navigate('/payment');
    }

    const direct_example = () => {
        const new_window = window.open('https://koispeak.com/static/user_outputs/example/1/English/index.html', '_blank');
        if (!new_window || new_window.closed) {
          if (window.confirm("请允许浏览器进行跳转")) {
            window.open('https://koispeak.com/static/user_outputs/example/1/English/index.html', '_blank');
          }
        }
        
      }

    const direct_contract = () => {
        navigate('/user_contract');
    }

    const direct_privacy = () => {
        navigate('/user_privacy');
    }

    const direct_about = () => {
        navigate('/about_us');
    }

    return {
        direct_account,
        direct_main,
        direct_mock,
        direct_history,
        direct_payment,
        direct_contract,
        direct_privacy,
        direct_about,
        direct_example,
        
    };
  };