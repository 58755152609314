import React, { useState, useEffect } from 'react';
import styles from './TestHistory.module.css';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '../utils/utils'; 
import axiosInstance from '../axiosInstance/logout';

export default function TestHistory() {
  const { direct_account, direct_main, direct_mock, _, direct_payment, direct_contract, direct_privacy, direct_about, direct_example } = useNavigation();
  const [isVisible, setIsVisible] = useState(false);
  const [money, setMoney] = useState();
  const [helpVisible, setHelpVisible] = useState(false);

  const help_close = () => {
    setHelpVisible(false);
  }

  const direct_help = () => {
    if (helpVisible) {
      setHelpVisible(false);
    }else {
      setHelpVisible(true);
    }
    
  }

  const think_again = () => {
    setIsVisible(false);
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
        try {
            // console.log(accessToken)
            axiosInstance.get('https://koispeak.com/api/user_profiles/profile/', {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('access')}`, // 确保从 localStorage 中获取 access token
              },
          })
          .then(response => {
            sessionStorage.setItem("last_test", response.data.last_test)
            sessionStorage.setItem("money", response.data.money)
            // console.log("moneymoney",  response.data.money)
            sessionStorage.setItem("history_count", response.data.history_count)
            sessionStorage.setItem("target_score", response.data.target_score)
            sessionStorage.setItem("highest_score", response.data.highest_score)
            sessionStorage.setItem("test_history", JSON.stringify(response.data.test_history))
              // 处理响应
          })
          .catch(error => {
              console.error("There was an error fetching the user profile!", error);
          });
        } catch (err) {
            console.log(err);
        }
    };
    fetchUserProfile();
  }, []);

  const [history_info, setHistoryInfo] = useState({});
  const [history_Keys, setHistoryKeys] = useState([]);
  const page_length = 11;
  const [page_index, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [current_history, setCurrentHistory] = useState([]);
  const [currentKeys, setCurrentKeys] = useState([]);

  useEffect(() => {
    setMoney(sessionStorage.getItem("money"));
  }, []);

  useEffect(() => {
    const data = sessionStorage.getItem("test_history");
    const parsedData = JSON.parse(data || '{}');
    // console.log("parsedData", parsedData)
    setHistoryInfo(parsedData);
    setHistoryKeys(Object.keys(parsedData).sort((a, b) => b - a));
  }, [sessionStorage.getItem("test_history")]); 

  
    
    useEffect(() => {
      // 根据 pageIndex 计算当前页面应显示的历史记录
      const start = page_index * page_length;
      const end = start + page_length;
      const currentKeys = history_Keys.slice(start, end);
      const history_length = Object.keys(history_info).length;
      // console.log("history_length", history_length)
      const total_page_index = Math.floor(history_length / page_length) + 1;
      setCurrentPage(page_index+1);
      setTotalPages(total_page_index);
      setCurrentKeys(currentKeys);
      // console.log("history_info", history_info)
      // console.log("currentKeys", currentKeys)
      setCurrentHistory(currentKeys.map(key => history_info[key]));
      // console.log("current_history", current_history)
  }, [page_index, history_Keys, history_info]);

    const handleNextPage = () => {
      if ((page_index + 1) * page_length < history_Keys.length) {
          setPageIndex(page_index + 1);
        }
    };

    const handlePrevPage = () => {
        if (page_index > 0) {
            setPageIndex(page_index - 1);
        }
    };

    const direct_practice = () => {
      // alert("口语练习暂时为开放!");
      setIsVisible(true);
    }


    function handleClick(score, url) {
      if (score !== "N/A") {
        window.open(url, "_blank");
      }else{
        alert("报告未生成完毕,请稍等");
        return;
      }
    }

    function renderHistoryItems(historyData, currentKeys) {
      const historyKeys = Object.keys(historyData); 
      // console.log("historyData", historyData)
      // console.log("currentKeys", currentKeys)
      return historyKeys.map((key, index) => {
          // 根据 index 计算 marginTop，第一个元素为 21px，其他为 0px
          const marginTop = index === 0 ? '21px' : '0px';
          let numericKey = parseInt(currentKeys[index], 10);
          const userUrl = `https://koispeak.com/static/user_outputs/${localStorage.getItem("account_name")}/${numericKey}/English/index.html`;
          // console.log("key", key)
          // console.log("numericKey", numericKey)
          return (
              <div key={key} className={styles.frame_a} style={{marginTop}} onClick={() => handleClick(historyData[key].score, userUrl)}>
                  <div className={styles.date_time}>
                      <span className={styles.cambridge_ielts_test}>{historyData[key].date}</span>
                  </div>
                  <div className={styles.frame_b}>
                      <span className={styles.travel_school_memory_childhood}>{historyData[key].name}</span>
                  </div>
                  <div className={styles.frame_c}>
                      <span className={styles.score_d}>{historyData[key].theme.replace(/\s+/g, ', ')}</span>
                  </div>
                  <div className={styles.frame_e}>
                      <span className={styles.text_d}>{historyData[key].score}</span>
                  </div>
              </div>
          );
      });
  }
  
  const [isHovered, setIsHovered] = useState(false);
    return (
        <div className={styles.main_container}>
      <div className={styles.macbook_air_home_page}>
        {isHovered && (
          <div className={styles.help_info}>
            <span className={styles.help_info_content}>
              KoiSpeak会保留最近30次模拟考试和套题练习的录音或者评分报告。点击栏目查看录音或者报告。
            </span>
        </div>
        )}
        <div className={styles.frame_1}>
          <div className={styles.about_us} onClick={direct_about}>
            <span className={styles.about_us_2}>关于我们</span>
          </div>
          <div className={styles.about_us_3} onClick={direct_contract}>
            <span className={styles.about_us_terms}>条款</span>
          </div>
          <div className={styles.about_us_privacy} onClick={direct_privacy}>
            <span className={styles.about_us_help}>隐私</span>
          </div>
          <div className={styles.about_us_4} onClick={direct_help}><span className={styles.logo_tentative}>帮助</span></div>
          
        </div>
        <div className={styles.flex_row_de}></div>
        <div className={styles.frame_5}>
          <div className={styles.frame_6}>
            <div className={styles.my_mock_exam_records}>
              <span className={styles.info}>我的模考记录</span>
              <div className={styles.frame_7}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)} ></div>
             
            </div>
            
            <div className={styles.frame_8}>
              <div className={styles.time}>
                <span className={styles.test_paper}>时间</span
                ><span className={styles.section_topic}>套题</span
                ><span className={styles.score}>每部分主题</span
                ><span className={styles.frame_9}>得分</span>
              </div>
              {renderHistoryItems(current_history, currentKeys)}
            </div>
            <div className={styles.frame_4e}>
              <div className={`${styles.group} ${page_index === 0 ? styles.disabled : ''}`}
              onClick={handlePrevPage} 
              disabled={page_index === 0}></div>
              <div className={styles.frame_4f}>
                {/* <div className={styles.ellipse}></div>
                <div className={styles.ellipse_50}></div>
                <div className={styles.ellipse_51}></div>
                <div className={styles.ellipse_52}></div> */}
                {currentPage} / {totalPages}
              </div>
              <div className={`${styles.group_53} ${(page_index + 1) * page_length >= history_Keys.length ? styles.disabled : ''}`}  
              onClick={handleNextPage} 
              disabled={(page_index + 1) * page_length >= history_Keys.length}>

              </div>
            </div>
          </div>
          <div className={styles.sidebar}>
            <div className={styles.frame_54}>
              <div className={styles.sidebar_home} onClick={direct_main}>
                <div className={styles.home}><div className={styles.icon}></div></div>
                <span className={styles.my_page}>我的主页</span>
              </div>
              <div className={styles.sidebar_home_55} onClick={direct_mock}>
                <div className={styles.mock_exam}>
                  <div className={styles.trophy}><div className={styles.icon_56}></div></div>
                </div>
                <span className={styles.my_page_57}>模拟考试</span>
              </div>
              <div className={styles.sidebar_home_58} onClick={direct_example}>
                <div className={styles.oral_practice}></div>
                <span className={styles.my_page_59}>查看样例</span>
              </div>
              <button className={styles.sidebar_home_5a}>
                <div className={styles.mock_exam_record}></div>
                <span className={styles.my_page_5b}>模考历史</span>
              </button>
            </div>
            <div className={styles.divider}><div className={styles.line}></div></div>
            <div className={styles.sidebar_home_5c} onClick={direct_account}>
              <div className={styles.my_account}></div>
              <span className={styles.my_page_5d}>我的账户</span>
            </div>
            {/* <div className={styles.sidebar_home_5e}><div className={styles.help}></div></div> */}
          </div>
        </div>
      </div>
      {helpVisible && (
        <div className={styles.help_main_container}>
          <div className={styles.help_close} onClick={help_close} />
          <span className={styles.help_add_wechat}>添加客服微信</span>
          <span className={styles.help_get_materials}>领取海量备考资料助你备考顺利</span>
          <div className={styles.help_image} />
        </div>
      )}
      {/* {isVisible && (
      <div className={styles.jump_main_container}>
        <div className={styles.jump_frame}>
          <span className={styles.jump_ready_to_go}>READY TO GO？</span>
        </div>
        <div className={styles.jump_line} />
        <span className={styles.jump_text_2}>
          每次模拟考试AI评分会花费20积分。
          <br />
          如在考试途中退出，积分将无法退回。请确保网络连接通常，并已做好考试准备。
        </span>
        <button className={styles.jump_frame_button}>
          <div className={styles.jump_frame_div}>
            <div className={styles.jump_my_points}>
              <span className={styles.jump_my_points_1}>我的积分：</span>
              <span className={styles.jump_points}>{money}</span>
            </div>
          </div>
          <div className={styles.jump_frame_2}>
            <div className={styles.jump_frame_3} onClick={direct_payment}>
              <span className={styles.jump_purchase_score}>购买积分</span>
            </div>
          </div>
        </button>
        <div className={styles.jump_flex_row_a} onClick={think_again}>
          <button className={styles.jump_small_button}>
            <span className={styles.jump_i_am_thinking} >我再想想</span>
          </button>
          <button className={styles.jump_small_button_4} onClick={start_test}>
            <span className={styles.jump_start_mock_exam}>开始模考</span>
          </button>
          <div className={styles.jump_rectangle} />
          <div className={styles.jump_rectangle_5} />
        </div>
        
      </div>
      )} */}
    </div>
    );
}
