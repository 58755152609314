import React from 'react';
import styles from './Privacy.module.css';
import { useNavigate  } from 'react-router-dom';

export default function UserPrivacy() {
  const navigate = useNavigate();
  const jumpBack = () => {
    navigate(-1);
  }
  return (
    <div className={styles.main_container}>
      <span className={styles.user_agreement}>隐私政策</span>
      <span className={styles.ai_ielts_speaking_assessment}>
      我们非常重视用户的隐私和个人信息保护。本隐私政策旨在帮助您了解我们如何收集、使用和保护您的个人信息。请仔细阅读以下内容：
        <br /><br />
        1. 信息收集
        <br />
        1.1 我们收集的信息包括：
        <br />
        - 注册信息：电子邮件地址或微信账号
        <br />
        - 使用信息：您参加模拟考试时输入的文字和音频信息
        <br />
        - 其他信息：您自愿提供的其他个人信息，如笔记本内容
        <br />
        1.2 我们可能通过cookies或类似技术收集某些信息，以改善用户体验和服务质量。
        <br /><br />
        2. 信息使用
        <br />
        2.1 我们使用收集的信息用于：
        <br />
        - 提供、维护和改进我们的雅思口语评估服务
        <br />
        - 进行数据整理、分析和研究，以改进我们的AI评估系统
        <br />
        - 使用大模型技术对您的口语表现进行分析和评估
        <br />
        - 与您沟通，解答问题和提供客户支持
        <br />
        - 发送服务相关的通知
        <br />
        2.2 我们可能会将您的信息用于大数据分析和AI模型训练，但会采取去标识化、匿名化等措施保护您的隐私。
        <br /><br />
        3. 信息共享
        <br />
        3.1 我们可能与以下第三方共享您的信息：
        <br />
        - 为提供服务必要的合作伙伴，如AI大模型服务提供商
        <br />
        - 根据法律法规要求或政府主管部门的强制性要求
        <br />
        3.2 我们会要求第三方对您的个人信息采取保密措施，并且只能按照我们的要求处理这些信息。
        <br /><br />
        4. 信息安全
        <br />
        4.1 我们采取各种安全措施保护您的个人信息，包括但不限于：
        <br />
        - 数据加密存储和传输
        <br />
        - 访问控制和身份认证
        <br />
        - 信息脱敏处理
        <br />
        - 定期安全评估
        <br />
        4.2 尽管我们会采取合理措施保护您的信息，但请理解互联网并非绝对安全的环境。
        <br /><br />
        5. 您的权利
        <br />
        5.1 您有权：
        <br />
        - 访问、更正或删除您的个人信息
        <br />
        - 撤回您的同意
        <br />
        - 注销您的账号
        <br />
        5.2 如需行使上述权利，请通过pandafanwu@foxmail.com与我们联系。
        <br /><br />
        6. 儿童隐私
        <br />
        本应用主要面向成年人，特别是大学生和职场人士。如果您是未满18岁的未成年人，请在监护人的陪同下使用我们的服务。
        <br /><br />
        7. 隐私政策的变更
        <br />
        我们可能会不时更新本隐私政策。更新后的隐私政策将在本页面上公布，请定期查看。
        <br /><br />
        8. 联系我们
        <br />
        如您对本隐私政策有任何疑问、意见或建议，请通过pandafanwu@foxmail.com与我们联系。
        <br /><br />
        最后更新日期：[2024.9.20]
      </span>
      <button className={styles.button} onClick={jumpBack}>
        <span className={styles.close}>关闭</span>
      </button>
    </div>
  );
}