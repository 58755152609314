// axiosInstance.js
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// 创建 Axios 实例
const axiosInstance = axios.create({
  timeout: 5000,
});

// 检查 Token 是否过期
const isTokenExpired = (token) => {
  if (!token) return false;
  const decoded = jwtDecode(token);
  const exp = decoded.exp; // 获取 Token 的过期时间
  return exp < Date.now() / 1000;
};

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('access');
    // 如果 token 过期
    if (isTokenExpired(token)) {
      // 清除 localStorage 中的 token
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      localStorage.removeItem('user_email');
      
      // 弹出提示框
      alert('Your session has expired. Please log in again.');
      
      // 跳转到登录页面
      window.location.href = '/login'; // 或者使用 history.push('/login') 如果你使用 React Router 的 history
      return Promise.reject("Token expired");
    }

    // 如果 token 没有过期，将 token 添加到请求头
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
