import React, { useState, useEffect } from 'react';
import styles from './Reset.module.css';
import { useNavigate, useLocation  } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import eyeOpen from "../assets/login/images/open.png"; // 导入图片
import eyeClose from "../assets/login/images/close.png"; // 导入图片
import axiosInstance from '../axiosInstance/logout';


export default function Reset() {
  const navigate = useNavigate();
  const location = useLocation();  // 确保在组件主体内调用

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [height, setHeight] = useState('580px');
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    try {
      const decodedToken = jwtDecode(token);
      setEmail(decodedToken.email);
    } catch (error) {
      console.error('Error decoding token:', error);
      alert('Error decoding token');
    }
  }, [location]);

  const ConfimRegistClick = () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }
    axiosInstance.post('https://koispeak.com/api/accounts/reset_password/', {
      email: email,
      password: password,
      password2: confirmPassword,
    })
    .then(response => {
      setSubmitted(true);
      setHeight('270px');
      alert("重置密码成功")
    })
    .catch(error => {
      console.log(error)
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage('Registration failed: ' + JSON.stringify(error.response.data.error));
      } else {
        setErrorMessage('Registration failed. Please try again.');
      }
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.image_traced} />
      <div className={styles.logo_box}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.rectangle} style={{ height: height }}>
      <span className={styles.reset_password}>重置密码</span>
      {!submitted && (
        <>
          <span className={styles.email_input}>邮箱*</span>
          <div className={styles.rectangle_1}>
            <span className={styles.email_example}>
              {email}
            </span>
          </div>
          <span className={styles.password}>密码*</span>
          <div className={styles.rectangle_2}>
            <input
              className={styles.password_input}
              type={showPassword ? "text" : "password"} 
              // placeholder="********"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className={styles.show_password} 
              onClick={togglePasswordVisibility}
              style={{
                backgroundImage: `url(${showPassword ? eyeClose : eyeOpen})`, // 根据状态动态更改背景图
              }}>
            </div>
          </div>
          <span className={styles.confirm_password}>确认密码*</span>
          <div className={styles.rectangle_3}>
            <input
              className={styles.password_confirm}
              type={showConfirmPassword ? "text" : "password"} 
              // placeholder="********"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={styles.show_confirm_password} 
              onClick={toggleConfirmPasswordVisibility}
              style={{
                backgroundImage: `url(${showConfirmPassword ? eyeClose : eyeOpen})`, // 根据状态动态更改背景图
              }}>
            </div>
          </div>
          <button className={styles.button} onClick={ConfimRegistClick}>
            <span className={styles.register}>确认</span>
          </button>
          {errorMessage && <span className={styles.error_message}>{errorMessage}</span>}
        </>
      )}
      {submitted && (
        <>
          <span className={styles.confirm_password}>提交成功！新密码已生效。</span>
          <button className={styles.button} onClick={() => navigate('/login')}>
            <span className={styles.register}>回到主页</span>
          </button>
        </>
        )}
      </div>
    
    </div>
  );
}
