import React, { useState, useEffect } from 'react';
import styles from './Payment.module.css';
import { useNavigation } from '../utils/utils'; 
import { loadStripe } from '@stripe/stripe-js';
import Dropdown from './drop';
import axiosInstance from '../axiosInstance/logout';

export default function Payment() {
    const { direct_account, direct_main, direct_mock, direct_history, _, direct_contract, direct_privacy, direct_about, direct_example } = useNavigation();
    const [clickedFrame, setClickedFrame] = useState(null);
    const [PaymentValue, setPaymentValue] = useState(0);
    const [point, setPoint] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [money, setMoney] = useState();
    const [promoCode, setPromoCode] = useState('');
    const [promoDiscount, setpromoDiscount] = useState(0);
    const [helpVisible, setHelpVisible] = useState(false);

    const help_close = () => {
      setHelpVisible(false);
    }

    const direct_help = () => {
      if (helpVisible) {
        setHelpVisible(false);
      }else {
        setHelpVisible(true);
      }
      
    }
    
    useEffect(() => {
      setMoney(sessionStorage.getItem("money"));
    }, []);
    const think_again = () => {
      setIsVisible(false);
    }
    const direct_practice = () => {
      // alert("口语练习暂时为开放!");
      setIsVisible(true);
    }
    const handleClick = (frameId) => {
      setClickedFrame(frameId); // 更新状态为被点击的框架ID
      switch (frameId) {
        case 'frame_b':
          setPaymentValue(19);
          setPoint(20);
          break;
        case 'frame_e':
          setPaymentValue(58);
          setPoint(60);
          break;
        case 'frame_12':
          setPaymentValue(95);
          setPoint(100);
          break;
        case 'frame_15':
          setPaymentValue(190);
          setPoint(200);
          break;
        default:
          setPaymentValue(0); // 如果没有匹配的框架ID，清除数值
      }
    };
    const checkout = async () => {
      const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      
      if (PaymentValue == 0) {
          alert("请选择支付产品");
          return;
      }
  
      try {
          // 构造请求体
          const requestData = {
              price: parseFloat(PaymentValue),
              coupon_code: promoCode,
              coupon_discount: parseFloat(promoDiscount),
              user_email: localStorage.getItem("user_email"),
              points: parseInt(point)
          };
  
          // 使用 axiosInstance 发送 POST 请求
          const response = await axiosInstance.post('https://koispeak.com/api/payment/create_checkout_session/', requestData);
          
          // 解析响应
          const result = response.data;
  
          if (response.status === 200) {
              await stripePromise.redirectToCheckout({ sessionId: result.id });
          } else {
              // 处理错误
              alert(result.error || 'Something went wrong.');
          }
      } catch (err) {
          alert('An error occurred. Please try again.');
      }
   };
    const getBorderStyle = (frameId) => ({
      border: clickedFrame === frameId ? "5px solid #505f98" : "none"
    });
    // const start_test = () => {
    //   let user_money = parseInt(sessionStorage.getItem('money') || '0'); 
    //   // console.log("user_money", user_money)
    //   if (user_money < 20){
    //     alert("您的积分不足,请充值")
    //   }else{
    //     user_money = user_money-20;
    //     sessionStorage.setItem("money", user_money);
    //     setMoney(user_money);
    //     try {
    //       fetch('https://koispeak.com/api/user_profiles/profile/', {
    //         method: 'PUT',
    //         headers: {
    //           'Content-Type': 'application/json', // 必须设置，以便正确发送 JSON 数据
    //           Authorization: `Bearer ${localStorage.getItem('access')}`, // 确保从 localStorage 中获取 access token
    //         },
    //         body: JSON.stringify({ money: user_money })
    //       })
    //       .then(response => {
    //         if (response.ok) {
    //           const user_email = localStorage.getItem("user_email")
    //           const encodedEmail = encodeURIComponent(user_email);
    //           const url = `https://koispeak.com/static/pages/pc1.html?email=${encodedEmail}`;
    //           const new_window = window.open(url, '_blank');
    //           if (!new_window || new_window.closed) {
    //             if (window.confirm("请允许浏览器进行跳转")) {
    //               window.open(url, '_blank');
    //             }
    //           }
    //           // const user_email = localStorage.getItem("user_email")
    //           // const encodedEmail = encodeURIComponent(user_email);
    //           // if (window.confirm("请允许浏览器进行跳转")) {
    //           //   const url = `https://koispeak.com/static/pages/pc1.html?email=${encodedEmail}`;
    //           //   window.open(url, '_blank');
    //           // }
    //           // window.open(url, '_blank');
    //         } else {
    //           // 如果响应状态不是 OK，抛出错误让 catch 块捕获
    //           throw new Error('Failed to update money');
    //         }
    //       })
    //     } catch (error) {
    //       alert('Error: ' + error.message);
    //     }
    //   }
    // }

    const handlePromoCode = (code, discount) => {
      setPromoCode(code);
      setpromoDiscount(discount/10)
      // console.log("Received promo code in parent:", code);
      // console.log("Received promo discount in parent:", discount/10);
  }

    return (
      <div className={styles.main_container}>
        <div className={styles.macbook_air_home}>
          <div className={styles.frame_1}>
            <div className={styles.about_us} onClick={direct_about}>
              <span className={styles.about_us_2}>关于我们</span>
            </div>
            <div className={styles.about_us_3} onClick={direct_contract}>
              <span className={styles.about_us_terms}>条款</span>
            </div>
            <div className={styles.about_us_4} onClick={direct_privacy}>
              <span className={styles.about_us_privacy}>隐私</span>
            </div>
            <div className={styles.about_us_5} onClick={direct_help}>
              <span className={styles.about_us_help}>帮助</span>
            </div>
          </div>
          <div className={styles.logo_tentative} />
          <div className={styles.flex_row_fde}>
            <div className={styles.frame_6}>
              <div className={styles.group}>
                <div className={styles.frame_7}>
                  <span className={styles.select_points_plan}> 选择积分计划</span>
                </div>
                <div className={styles.divider}>
                  <div className={styles.line} />
                </div>
                <div className={styles.divider_8}>
                  <div className={styles.line_9} />
                </div>
              </div>
              <div className={styles.group_a}>
              <div className={styles.frame_b} style={getBorderStyle('frame_b')} onClick={() => handleClick('frame_b')}>
                  <div className={styles.frame_c}>
                    <span className={styles.currency}>¥19</span>
                  </div>
                  <div className={styles.frame_d}>
                    <span className={styles.points_ai_exam}>
                      20积分
                      <br />
                      1次AI模考打分
                      <br />
                      永久有效
                    </span>
                  </div>
                </div>
                <div className={styles.frame_e} style={getBorderStyle('frame_e')} onClick={() => handleClick('frame_e')}>
                  <div className={styles.frame_f}>
                    <span className={styles.currency_10}>¥58</span>
                  </div>
                  <div className={styles.frame_11}>
                    <span className={styles.score_ai_permanent}>
                      60积分
                      <br />
                      3次AI模考打分
                      <br />
                      永久有效
                    </span>
                  </div>
                </div>
                <div className={styles.frame_12} style={getBorderStyle('frame_12')} onClick={() => handleClick('frame_12')}>
                  <div className={styles.frame_13}>
                    <span className={styles.price}>¥95</span>
                  </div>
                  <div className={styles.frame_14}>
                    <span className={styles.score_ai_instant}>
                      100积分
                      <br />
                      即5次AI模考打分
                      <br />
                      永久有效
                    </span>
                  </div>
                </div>
                <div className={styles.frame_15} style={getBorderStyle('frame_15')} onClick={() => handleClick('frame_15')}>
                  <div className={styles.frame_16}>
                    <span className={styles.price_17}>¥190</span>
                  </div>
                  <div className={styles.frame_18}>
                    <span className={styles.score_ai_permanent_19}>
                      200积分
                      <br />
                      10次AI模考打分
                      <br />
                      永久有效
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.frame_1a}>
                <button className={styles.button}>
                  <span className={styles.work_in_progress}>WORK IN PROGRESS</span>
                </button>
                <span className={styles.customized_tracking_learning}>
                  定制化可追踪学习计划、笔记本、AI智能导师等功能优化中...
                </span>
              </div>
              {/* <span className={styles.invitation_code}>
                *如有邀请码/优惠码，请在支付页面输入
              </span> */}
              <Dropdown onPromoCodeSubmit={handlePromoCode} />
              <button className={styles.frame_1b} onClick={checkout}>
                <div className={styles.small_button}>
                  <span className={styles.jump_payment}>选好了，去支付</span>
                </div>
                {/* <div className={styles.rectangle} /> */}
              </button>
            </div>
            <div className={styles.sidebar}>
              <div className={styles.frame_1c}>
                <div className={styles.sidebar_my_home} onClick={direct_main}>
                  <div className={styles.home}>
                    <div className={styles.icon} />
                  </div>
                  <span className={styles.my_home}>我的主页</span>
                </div>
                <div className={styles.sidebar_my_home_1d} onClick={direct_mock}>
                  <div className={styles.mock_exam}>
                    <div className={styles.trophy}>
                      <div className={styles.icon_1e} />
                    </div>
                  </div>
                  <span className={styles.my_homepage}>模拟考试</span>
                </div>
                <div className={styles.sidebar_my_homepage} onClick={direct_example}>
                  <div className={styles.oral_practice} />
                  <span className={styles.my_homepage_1f}>查看样例</span>
                </div>
                <div className={styles.sidebar_my_homepage_20} onClick={direct_history}>
                  <div className={styles.mock_exam_record} />
                  <span className={styles.my_homepage_21}>模考历史</span>
                </div>
              </div>
              <div className={styles.divider_22}>
                <div className={styles.line_23} />
              </div>
              <button className={styles.sidebar_my_homepage_24} onClick={direct_account}>
                <div className={styles.my_account} />
                <span className={styles.my_homepage_25}>我的账户</span>
              </button>
              <div className={styles.sidebar_my_homepage_26}>
                {/* <div className={styles.help} /> */}
              </div>
            </div>
          </div>
        </div>
        {helpVisible && (
        <div className={styles.help_main_container}>
          <div className={styles.help_close} onClick={help_close} />
          <span className={styles.help_add_wechat}>添加客服微信</span>
          <span className={styles.help_get_materials}>领取海量备考资料助你备考顺利</span>
          <div className={styles.help_image} />
        </div>
      )}
        {/* {isVisible && (
      <div className={styles.jump_main_container}>
        <div className={styles.jump_frame}>
          <span className={styles.jump_ready_to_go}>READY TO GO？</span>
        </div>
        <div className={styles.jump_line} />
        <span className={styles.jump_text_2}>
          每次模拟考试AI评分会花费20积分。
          <br />
          如在考试途中退出，积分将无法退回。请确保网络连接通常，并已做好考试准备。
        </span>
        <button className={styles.jump_frame_button}>
          <div className={styles.jump_frame_div}>
            <div className={styles.jump_my_points}>
              <span className={styles.jump_my_points_1}>我的积分：</span>
              <span className={styles.jump_points}>{money}</span>
            </div>
          </div>
          <div className={styles.jump_frame_2}>
            <div className={styles.jump_frame_3} onClick={think_again}>
              <span className={styles.jump_purchase_score}>购买积分</span>
            </div>
          </div>
        </button>
        <div className={styles.jump_flex_row_a} onClick={think_again}>
          <button className={styles.jump_small_button}>
            <span className={styles.jump_i_am_thinking} >我再想想</span>
          </button>
          <button className={styles.jump_small_button_4} onClick={start_test}>
            <span className={styles.jump_start_mock_exam}>开始模考</span>
          </button>
          <div className={styles.jump_rectangle} />
          <div className={styles.jump_rectangle_5} />
        </div>
      </div>
      )} */}
      </div>
    );
  }
  